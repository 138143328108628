

export function Header2({ color, children }) {
  return (
    <h2
      className={`text-6xl font-extrabold text-center uppercase italic pb-5 ${color ?? 'text-black'}`}
      style={{
        textShadow: '#ff66c485 -4px -3px 0px',
      }}
    >
      {children}
    </h2>
  )
}