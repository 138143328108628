import { TbPaperBag, TbTruckDelivery } from 'react-icons/tb'
import { PrimaryButton } from './buttons'
import { useNavigate } from 'react-router-dom'

function OrderButtons (props) {

  const navigate = useNavigate()

  function goToDeliveryPage() {
    navigate("/delivery-partners")
  }

  return (
    <div
      className="flex flex-row space-x-1 w-full"
      {...props}
    >
      <PrimaryButton
        href={'tel:01912521471'}
        style={{
          borderBottomLeftRadius: '25px',
          borderTopLeftRadius: '25px',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          width: '100%',
          height: '55px',
        }}
      >
        <div className={'flex flex-row justify-start items-center space-x-2'}>
          <TbPaperBag size={'lg'} style={{
            maxWidth: '30px',
          }}/>
          <div className={'flex flex-col justify-start items-start space-y-0'}>
            <div className={'font-semibold text-xs h-3'}>CALL &</div>
            <div className={'font-bold'}>COLLECT</div>
          </div>
        </div>
      </PrimaryButton>
      <PrimaryButton
        onClick={goToDeliveryPage}
        style={{
          borderBottomLeftRadius: '0',
          borderTopLeftRadius: '0',
          borderBottomRightRadius: '25px',
          borderTopRightRadius: '25px',
          width: '100%',
          height: '55px',

        }}>
        <div className={'flex flex-row justify-start items-center space-x-2'}>
          <TbTruckDelivery size={'lg'} style={{
            maxWidth: '30px',
          }}/>
          <div className={'flex flex-col justify-start items-start space-y-0'}>
            <div className={'font-semibold text-xs h-3'}>ORDER</div>
            <div className={'font-bold'}>DELIVERY</div>
          </div>
        </div>
      </PrimaryButton>
    </div>
  )
}

export default OrderButtons