import { createContext, useState } from 'react'

export const AppContext = createContext({
  isOrderOnlineModalShowing: false,
  setIsOrderOnlineModalShowing: (val) => null,
})

export const AppProvider = (props) => {
  const [isOrderOnlineModalShowing, setIsOrderOnlineModalShowing] = useState(false)

  return (
    <AppContext.Provider value={{
      isOrderOnlineModalShowing: isOrderOnlineModalShowing,
      setIsOrderOnlineModalShowing: setIsOrderOnlineModalShowing,
    }} >
      {props.children}
    </AppContext.Provider>
  )
}