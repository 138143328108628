import logo from '../assets/logo_color_primary.png'
import uberLogo from '../assets/delivery-partner-logos/Uber-Eats-logo.jpeg'
import justEatLogo from '../assets/delivery-partner-logos/Just-Eat-logo.jpeg'
import deliverooLogo from '../assets/delivery-partner-logos/Deliveroo-Logo.jpeg'

import singleBurger from '../assets/product/products_square/single_large.jpeg'
import doubleBurger from '../assets/product/products_square/double_large.jpeg'
import tripleBurger from '../assets/product/products_square/triple_large.jpeg'
import singleOnBurger from '../assets/product/products_square/onion_single_large.jpeg'
import doubleOnBurger from '../assets/product/products_square/onion_double_large.jpeg'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/bundle'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useContext, useState } from 'react'
import { AppContext } from '../AppProvider'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'

import { FaInstagram, FaTiktok } from 'react-icons/fa'
import { Button } from '../components/buttons'
import OrderButtons from '../components/OrderButtons'
import { Header2 } from '../components/text'
import { useNavigate } from 'react-router-dom'

const JUST_EAT_URL = 'https://www.just-eat.co.uk/restaurants-moza-burger-whitley-bay/menu'
const UBER_URL = 'https://www.ubereats.com/store/moza-burger/37It3aXyRViogy27SPuIoA'
const DELIVEROO_URL = 'https://deliveroo.co.uk/menu/Newcastle/north-shields/sheba-tandoori'

function LandingPage () {

  const navigate = useNavigate()

  function goToDeliveryPage() {
    navigate("/delivery-partners")
  }

  return (
    <div className={`w-full bg-primary-500`}>

      {/* Mobile only order button */}
      <div
        className="w-full flex flex-row md:flex-row space-x-3 items-center justify-center bg-primary-500 text-white py-3 px-1 md:px-10 md:hidden"
        style={{
          height: `4rem`,
          position: 'fixed',
          bottom: 0,
          zIndex: 10,
        }}
      >
        <OrderButtons />
      </div>


      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        loop
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        pagination={{ clickable: true }}
        navigation
      >
        <SwiperSlide>
          <div
            className={`flex flex-col items-center justify-center bg-[url(./assets/banners/burger_wall_banner_lg.jpeg)] lg:bg-[url(./assets/banners/burger_wall_banner.jpeg)] min-h-[50vh] md:min-h-[60vh]`}
            style={{
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
          >
            <h3
              className={'font-extrabold italic text-center text-white text-7xl sm:text-8xl md:text-9xl'}>
              EAT <br/>
              SLEEP <br/>
              BURGERS
            </h3>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className={`flex flex-col space-y-10 items-center justify-center bg-[url(./assets/banners/text_wall_banner_lg.jpeg)] lg:bg-[url(./assets/banners/text_wall_banner.jpeg)] min-h-[50vh] md:min-h-[60vh]`}
            style={{
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
          >
            <h3
              className={'font-extrabold italic text-center text-white text-7xl sm:text-8xl md:text-9xl'}>
              SMASH BURGERS <br/>
              from £5
            </h3>

            <Button onClick={goToDeliveryPage}> ORDER NOW </Button>
          </div>
        </SwiperSlide>
        {/*<SwiperSlide>*/}
        {/*  <div className={`bg-[url(./assets/banners/eat_sleep_burgers.png)]`}*/}
        {/*       style={{*/}
        {/*         minHeight: `60vh`,*/}
        {/*         backgroundRepeat: 'no-repeat',*/}
        {/*         backgroundPosition: 'center',*/}
        {/*         backgroundSize: 'cover',*/}
        {/*       }}*/}
        {/*  />*/}
        {/*</SwiperSlide>*/}
      </Swiper>


      {/*<div*/}
      {/*  className={`w-full flex flex-1 text-white bg-primary-500*/}
      {/*   bg-[url(./assets/banners/eat_sleep_burgers.png)]*/}
      {/*  `}*/}
      {/*  style={{*/}
      {/*    minHeight: `60vh`,*/}
      {/*    backgroundRepeat: 'no-repeat',*/}
      {/*    backgroundPosition: 'center',*/}
      {/*    backgroundSize: 'cover',*/}
      {/*  }}*/}
      {/*>*/}

      {/*  <div*/}
      {/*    className="flex flex-col flex-1 items-center justify-center space-y-4 text-white w-full md:w-4/6 pt-20"*/}

      {/*    style={{*/}
      {/*      padding: `33px 22px 25px`,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <div*/}
      {/*      className="flex flex-col items-center justify-center space-y-4"*/}
      {/*      style={{*/}
      {/*        padding: `33px 22px 25px`,*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <p className="text-xl font-bold uppercase text-center pb-16 pt-16 sm:tracking-[0.8rem] tracking-[2.5vw]">*/}
      {/*        Burgers • Fries*/}
      {/*      </p>*/}
      {/*      <p className=" text-center">*/}
      {/*        90 Whitley Road, Whitley Bay, NE25 8HR*/}
      {/*      </p>*/}
      {/*      <p className=" text-center">*/}
      {/*        Wednesday - Saturday • 6:00pm - 9:30pm*/}
      {/*      </p>*/}
      {/*    </div>*/}
      {/*    /!*<p className="md:text-xl text-center">*!/*/}
      {/*    /!*  We love burgers and we're tired of mediocre burgers.<br/> Our burgers always taste good with unique flavours.*!/*/}
      {/*    /!*</p>*!/*/}
      {/*    /!*<p className="text-2xl font-medium">*!/*/}
      {/*    /!*  Classic smash burgers with our Moza special flavours.*!/*/}
      {/*    /!*</p>*!/*/}
      {/*    <div className={"flex flex-row space-x-3 pb-16"}>*/}
      {/*      <OrderNowButton/>*/}

      {/*      <CallNowButton />*/}

      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}


      {/*<div className="w-full flex text-white"*/}
      {/*     style={{*/}
      {/*       minHeight: `50vh`,*/}
      {/*       backgroundImage: `url(${burgerRed})`,*/}
      {/*       backgroundRepeat: 'no-repeat',*/}
      {/*       backgroundPosition: 'center',*/}
      {/*       backgroundSize: '100vw',*/}
      {/*     }}*/}
      {/*>*/}

      {/*  <div*/}
      {/*    className="flex flex-col items-center justify-center align-middle w-full h-full p-10 space-y-3"*/}
      {/*    style={{ backgroundColor: `rgba(0, 0, 0, 0.8)` }}*/}
      {/*  >*/}

      {/*    <p className="text-2xl font-medium text-center">*/}
      {/*      Burgers that are <u>always</u> tasty. It's simple.*/}
      {/*    </p>*/}
      {/*    <OrderNowButton/>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div
        id={'burgers'}
        className="w-full flex flex-col justify-center items-center bg-white text-white py-10"
      >
        <Header2>
          The Burgers
        </Header2>
        <div
          id={'burgers'}
          className="w-full flex flex-wrap flex-row justify-center items-center text-white"
        >


          <MenuItem
            image={singleBurger}
            label={'Single Classic'}
            desc={'Our delicious single beef patty smash burger topped with cheese, gherkins, lettuce, tomato, and our house special sauce.'}
          />
          <MenuItem
            image={doubleBurger}
            label={'Double Classic'}
            desc={'Our delicious double beef patty smash burger topped with cheese, gherkins, lettuce, tomato, and our house special sauce.'}
          />
          <MenuItem
            image={tripleBurger}
            label={'Triple Classic'}
            desc={'Our delicious triple beef stacked smash burger topped with cheese, gherkins, lettuce, tomato, and our house special sauce.'}
          />
          <MenuItem
            image={singleOnBurger}
            label={'LA Burger'}
            desc={'Our delicious single beef patty smash burger cooked with super thin sliced onions and topped with cheese, gherkins, lettuce, tomato, and our house special sauce.'}
          />
          <MenuItem
            image={doubleOnBurger}
            label={'Double LA Burger'}
            desc={'Our delicious double patty smash burger cooked with super thin sliced onions and topped with cheese, gherkins, lettuce, tomato, and our house special sauce.'}
          />
          {/*<MenuItem*/}
          {/*  image={doubleOnBurger}*/}
          {/*  label={'Moza Rica'}*/}
          {/*  desc={'Our delicious double patty smash burger cooked with super thin sliced onions and topped with cheese, gherkins, lettuce, tomato, and our house special sauce.'}*/}
          {/*/>*/}
          {/*<MenuItem*/}
          {/*  image={doubleOnBurger}*/}
          {/*  label={'Moza Stacked'}*/}
          {/*  desc={'Our delicious double patty smash burger cooked with super thin sliced onions and topped with cheese, gherkins, lettuce, tomato, and our house special sauce.'}*/}
          {/*/>*/}


        </div>
      </div>


      <div
        className={`w-full flex flex-col flex-1 justify-end text-white bg-primary-500 pt-10
         bg-[url(./assets/banners/its_moza_banner.png)]
        `}
        style={{
          minHeight: `110vh`,
          backgroundRepeat: 'repeat-y',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
        }}
      >

        <div
          className={`w-full flex flex-col text-white pt-[30vh]
        `}
          style={{
            background: 'linear-gradient(0deg, rgba(140,82,255,1) 69%, rgba(255,255,255,0) 100%)'
          }}
        >
          <Header2 color={'text-white'}>
            Our Stores
          </Header2>

          <div
            className="flex flex-1 flex-col justify-center items-center w-full px-4 pb-8">

            <div
              className="flex flex-col justify-center items-center bg-white text-black py-10 rounded-lg space-y-3 md:w-4/6 px-4"
              style={{
                // backdropFilter: 'blur(30px)',
                // backgroundColor: 'rgba(255,255,255,0.5)',
                // boxShadow: '#ff66c485 -4px -3px 0px',

                boxShadow: 'rgba(0,0,0,0.3) 0px 10px 15px',
                borderRadius: '8px',
              }}
            >
              <h3 className="text-3xl font-bold text-center uppercase italic">
                Whitley Bay
              </h3>

              <p className="text-xl text-center ">
                90 Whitley Road, Whitley Bay, NE26 2NE
              </p>

              <p className="text-lg text-center text-gray-700">
                Come visit us at our pop-up store, we're at Sheba Tandoori in
                Whitley Bay.
              </p>

              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2286.1730813218473!2d-1.440695622279047!3d55.04018317282875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487e6f13c085e19b%3A0x4553b4431e0c7a09!2sMoza%20Burger!5e0!3m2!1sen!2suk!4v1703783090633!5m2!1sen!2suk"
                width="100%" height="450" allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>

            </div>

          </div>
        </div>
      </div>



      {/*<div*/}
      {/*  id={'locations'}*/}
      {/*  className="w-full flex flex-col text-black space-y-2 py-10 px-4 border-x-4 border-y-2 border-black"*/}
      {/*  style={{*/}
      {/*    backgroundColor: '#FFFB73'*/}
      {/*  }}*/}
      {/*>*/}

      {/*  <h2 className="text-4xl font-bold text-center uppercase pb-5">Where We*/}
      {/*    Are</h2>*/}


      {/*</div>*/}



      <OrderModal/>
    </div>
  )
}

function OrderModal () {
  const {
    isOrderOnlineModalShowing,
    setIsOrderOnlineModalShowing,
  } = useContext(AppContext)

  if (isOrderOnlineModalShowing) {
    return (
      <div
        className={'fixed w-full h-full flex justify-center items-center'}
        style={{
          backgroundColor: `rgba(0, 0, 0, 0.8)`,
          top: 0,
          position: 'fixed',
          zIndex: 10,
        }}
        onClick={() => setIsOrderOnlineModalShowing(false)}
      >
        <div
          className={'flex flex-col space-y-2 justify-start items-center bg-white rounded-lg px-12 pt-6 pb-10'}
          onClick={(e) => e.stopPropagation()}
        >
          <h2 className="text-2xl font-bold text-center pb-4">Order Online</h2>

          {/*<PrimaryButton href={'https://moza-burger.square.site'}>*/}
          {/*  Order Collection*/}
          {/*</PrimaryButton>*/}

          {/*<hr/>*/}

          <Button
            href={UBER_URL}
            target={'_blank'}>
            <img src={uberLogo} width={'120px'} alt={'Uber Eats'}/>
          </Button>
          <Button
            href={JUST_EAT_URL}
            target={'_blank'}>
            <img src={justEatLogo} width={'120px'} alt={'Just Eat'}/>
          </Button>
          <Button
            href={DELIVEROO_URL}
            target={'_blank'}>
            <img src={deliverooLogo} width={'120px'} alt={'Deliveroo'}/>
          </Button>
          {/*<Button*/}
          {/*  href={'https://deliveroo.co.uk/menu/Newcastle/north-shields/sheba-tandoori'}*/}
          {/*  target={'_blank'}>*/}
          {/*  <img src={deliverooLogo} width={'120px'} alt={'Deliveroo'}/>*/}
          {/*</Button>*/}
        </div>
      </div>
    )
  }

  return null
}

function MenuItem ({ image, label, desc }) {
  return (
    <div
      className={'flex flex-col bg-white text-black m-5 w-full sm:w-5/12 xl:w-3/12 relative'}
      style={{
        boxShadow: 'rgba(0,0,0,0.3) 0px 10px 15px',
        borderRadius: '8px',
      }}
    >
      {/*<img src={image} className={'rounded-2xl'} style={{ width: '100%', maxWidth: '700px', height: '200px' }}/>*/}

      <div
        style={{
          height: `250px`,
          width: '100%',
          backgroundImage: `url(${image})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}>

      </div>

      <div
        className={'px-3 py-5 text-xl flex flex-col text-black'}
      >
        <span
          className={'font-bold text-xl'}
        >
        {label}
      </span>
        <span
          className={'text-sm'}
        >
        {desc}
        </span>
      </div>

    </div>
  )
}

export default LandingPage
