import logo from '../assets/logo_color_primary.png'
import { FaInstagram, FaTiktok } from 'react-icons/fa'

function Footer() {

  return (
    <div
      id={'footer'}
      className="w-full flex flex-col text-white bg-black space-y-10 py-10 px-5 md:px-10 pb-32"
    >

      <img src={logo} height={'60px'} style={{ width: '200px' }}/>

      <div className="flex flex-col space-y-3">
        <div className="flex flex-col space-y-1">
          <h3 className="font-bold">Email</h3>
          <h3><a href="mailto:info@mozaburger.com">info@mozaburger.com</a>
          </h3>
        </div>

        <div className="flex flex-col space-y-1">
          <h3 className="font-bold">Phone</h3>
          <h3><a href="tel:01912521471">01912521471</a></h3>
        </div>
      </div>

      <div
        className="flex flex-row md:flex-row justify-center items-center space-x-5 w-full pt-5">

        <FaInstagram/>
        <FaTiktok/>

        {/*<img src={justEatLogoOrange} style={{ widh: '50px', width: '280px' }}*/}
        {/*     alt={'Just Eat'}/>*/}
        {/*<img src={uberLogoOrder} style={{ height: '50px', width: '112px' }}*/}
        {/*     alt={'Uber Eats'}/>*/}
        {/*<img src={deliverooLogo} style={{height: '50px', width: '182px'}} alt={'Deliveroo'}/>*/}
      </div>
    </div>
  )
}

export default Footer