import React from 'react'

export function Button (props) {
  if (props.href) {
    return (
      <a
        className={' bg-white text-black px-5 py-3 rounded-full font-bold uppercase shadow-md text-center flex justify-center items-center'}
        style={{ minWidth: '160px', height: ' 50px' }}
        {...props}
      >
      </a>
    )
  }
  return (
    <button
      className={' bg-white text-black px-5 py-3 rounded-full font-bold uppercase shadow-md text-center flex justify-center items-center'}
      style={{ minWidth: '160px', height: ' 50px' }}
      {...props}
    >
    </button>
  )
}

export function PrimaryButton (props) {
  return (
    <Button
      className={' bg-primary-500 text-white px-5 py-3 rounded font-bold uppercase shadow-md text-center flex justify-center items-center'}
      {...props}
    />
  )
}