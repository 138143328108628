import { Header2 } from '../components/text'


import uberLogo from '../assets/delivery-partner-logos/UE_Logo_Vertical_RGB_web_GreenBG.svg'
import justEatLogo from '../assets/delivery-partner-logos/just-eat-logo-white.png'
import deliverooLogo from '../assets/delivery-partner-logos/Deliveroo-Logo-white.png'
import { Button } from '../components/buttons'
import OrderButtons from '../components/OrderButtons'


const JUST_EAT_URL = 'https://www.just-eat.co.uk/restaurants-moza-burger-whitley-bay/menu'
const UBER_URL = 'https://www.ubereats.com/store/moza-burger/37It3aXyRViogy27SPuIoA'
const DELIVEROO_URL = 'https://deliveroo.co.uk/menu/Newcastle/north-shields/sheba-tandoori'

function DeliveryPage() {

  return (
    <div className={`w-full bg-white pt-6`}>

      <div className={`w-full flex flex-col justify-center items-center px-2 pb-4`}>
        <div className={`w-full flex flex-col justify-center items-center max-w-2xl`}>
          <OrderButtons />
        </div>
      </div>

      <Header2>
        Our Delivery Partners
      </Header2>


      <div className={`w-full flex flex-row flex-wrap justify-center items-center`}>

        <Card
          bgColor={'#06C167'}
          href={UBER_URL}
        >
            <img src={uberLogo} width={'120px'} height={'120px'} alt={'Uber Eats'}/>
        </Card>
        <Card
          bgColor={'#ff8100'}
          href={JUST_EAT_URL}
        >
            <img src={justEatLogo} width={'140px'} height={'140px'} alt={'Just Eat'}/>
        </Card>
        <Card
          bgColor={'#00CDBC'}
          href={DELIVEROO_URL}
        >
            <img src={deliverooLogo} width={'120px'} height={'120px'} alt={'Deliveroo'}/>
        </Card>
      </div>

    </div>
  )
}

function Card({bgColor, href, children}) {

  return (
    <a href={href} target={"_blank"}>
      <div
        className={`flex flex-col justify-center items-center bg-white text-black m-5 w-[250px] h-[250px]`}
        style={{
          boxShadow: 'rgba(0,0,0,0.3) 0px 10px 15px',
          borderRadius: '8px',
          backgroundColor: bgColor ?? 'white'
        }}
      >
        {children}
        <div className={`text-2xl font-bold underline text-white uppercase pt-4`}>Order Now</div>
      </div>
    </a>
  )
}

export default DeliveryPage