import logo from '../assets/logo_color_primary.png'
import OrderButtons from './OrderButtons'

function Navbar() {

  return (
    <>
      {/* Mobile*/}
      <div
        className="h-[60px] md:h-[70px] w-full flex flex-col md:flex-row space-y-3 md:space-y-0 items-center justify-between bg-white text-white py-2 px-1 md:px-10"
        style={{
          position: 'fixed',
          top: 0,
          zIndex: 10,
          boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.19)',
        }}
      >
        <a href={"/"}>
          <img src={logo} alt={'logo'}
               style={{ height: '45px' }}
          />
        </a>
        <div
          className="hidden md:flex flex-row"
        >
          <OrderButtons />
        </div>
      </div>
    </>
  )
}

export default Navbar