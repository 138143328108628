import './App.css'
import * as React from "react";
import {
  createBrowserRouter, Outlet,
  RouterProvider, useLocation,
} from 'react-router-dom'
import LandingPage from './pages/LandingPage'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import DeliveryPage from './pages/DeliveryPage'
import { useEffect } from 'react'

function App () {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <AppLayout />,
      children: [
        {
          path: "/",
          element: <LandingPage />,
        },
        {
          path: "delivery-partners",
          element: <DeliveryPage />,
        },
      ],
    },
  ]);

  return (
    <div className={`h-screen w-full bg-primary-500`}>
      <RouterProvider router={router} />
    </div>
  )
}

function AppLayout() {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={`flex flex-col space-y-0 h-screen w-full bg-primary-500 overflow-x-clip`}>
      <Navbar />
      <div className={`flex flex-col space-y-0 w-full pt-[60px] md:pt-[70px]`} style={{
      }}>
        <Outlet />
      </div>
      <Footer />
    </div>
  )
}

export default App
